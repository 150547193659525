import DateFnsUtils from "@date-io/date-fns";
import {
  Button,
  Divider,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography
} from "@material-ui/core";
import Paper from "@material-ui/core/Paper";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import GetAppIcon from "@material-ui/icons/GetApp";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import React from "react";
import { ApiProps } from "../../props/ApiProps";
import { apiService } from "../../services/api.service";

const useStyles = makeStyles((theme) => ({
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  projectheading: {
    fontWeight: 500,
    letterSpacing: 0.5,
    fontSize: 17,
  },
  divider: {
    height: 4,
    backgroundImage: "linear-gradient(90deg, #00b0ff, #00C853)",
    width: "100%",
    marginTop: 15,
  },
  headcontainer: {
    margin: "15px 0%",
  },
  projectbtn: {
    "& button": {
      float: "right",
      textTransform: "inherit",
      fontWeight: 500,
      fontSize: 12,
      letterSpacing: 0.5,
      borderRadius: 20,
      color: "white",
    },
  },
  table: {
    minWidth: 650,
  },
}));

function createData(name, calories, fat) {
  return { name, calories, fat };
}
const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: "#4cc8ff",
    color: theme.palette.common.black,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);
const downloadS3file = () => () => {
  if (ApiProps.baseUrl.includes("dev")) {
    apiService.getFile("winappdev/Setup.msi");
  } else {
    apiService.getFile("winapp/Setup.msi");
  }
};
const rows = [
  createData(
    "MDOT Road Plans",
    "Road Plans",
    "https://mdotjboss.state.mi.us/stdplan/standardPlansHome.htm"
  ),
  createData(
    "MDOT Sign Standards",
    "Sign Standards",
    "https://mdotjboss.state.mi.us/TSSD/getCategoryDocuments.htm?categoryPrjNumbers=1403886,2028779,1797786,2668451,1403889,1403890,2612964,1403888,2668453&category=Traffic%20Signing"
  ),
  createData(
    "2012 and 2020 MDOT Standard Specifications for Construction",
    "Specifications",
    "https://mdotjboss.state.mi.us/SpecProv/specBookHome.htm"
  ),
  // createData(
  //   "2020 MDOT Standard Specifications for Construction",
  //   "Specifications",
  //   "https://www.michigan.gov/mdot/-/media/Project/Websites/MDOT/Business/Construction/Standard-Specifications-Construction/2020-Standard-Specifications-Construction.pdf"
  // ),
  createData(
    "MDOT MSG",
    "Material Source Guide",
    "https://www.michigan.gov/mdot/business/construction/materials-source-guide"
  ),
  createData(
    "Time Reporting",
    "Time reporting",
    "https://online4.timeanywhere.com/novatime/wslogin.aspx?CID=22595496-72c1-4838-a0bf-85152438304c&Site=AWS"
  ),
  createData(
    "Miss Dig Positive Response",
    "Positive Response",
    "http://posr.missdig811.org/"
  ),
  // createData(
  //   "Applications",
  //   "Current Job Openings",
  //   "https://actiontraffic.applicantpool.com/jobs/"
  // ),
  createData(
    "KPA App",
    "KPA App",
    "https://actiontraffic.kpaehs.com/"
  ),
  createData("Foreman App", "1.0.2.0 (May-2023)", ""),
];
function ResourcesComp() {
  const classes = useStyles();
  //const [selectedDate, handleDateChange] = useState(new Date());
  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <Grid container direction="row">
        <Grid className={classes.headcontainer} container item xs={12} md={12}>
          <Grid item xs={6} className={classes.projectheading}>
            <Typography variant="h6" gutterBottom>
              Resources
            </Typography>
          </Grid>
          <Grid item xs={6} className={classes.projectbtn}>
            <Button
              variant="contained"
              color="primary"
              startIcon={<GetAppIcon />}
              onClick={downloadS3file()}
            >
              Download Foreman App
            </Button>
          </Grid>
          <Divider className={classes.divider} />
        </Grid>

        <Grid container direction="row">
          {/* <div className="picker">
            <DatePicker
              views={["year"]}
              label="For Year"
              minDate={new Date("2018-01-01")}
              value={selectedDate}
              onChange={handleDateChange}
              animateYearScrolling
            />
          </div> */}

          <TableContainer component={Paper}>
            <Table className={classes.table} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <StyledTableCell>Name</StyledTableCell>
                  <StyledTableCell align="left">Description</StyledTableCell>
                  <StyledTableCell align="left">Link</StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {rows.map((row) => (
                  <TableRow key={row.name}>
                    <StyledTableCell scope="row">{row.name}</StyledTableCell>
                    <StyledTableCell>{row.calories}</StyledTableCell>
                    <StyledTableCell>
                      {
                        <a target="_blank" href={row.fat}>
                          {row.fat}
                        </a>
                      }
                    </StyledTableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>
    </MuiPickersUtilsProvider>
  );
}
export default ResourcesComp;
